import './intro.scss'
import { init } from 'ityped'
import { useEffect, useRef } from 'react'

export default function Intro() {

  const textRef = useRef();

  useEffect(()=> {
    init(textRef.current, { showCursor: true, backDelay: 1500, backSpeed:60, strings: ['Developer', 'Designer', 'Artist', 'Producer' ] })

  }, [])
  return (
    <div className="intro" id="intro"> 
      <div className="left">
        <div className="imgContainer">
          <img src="assets/Picture4.png" alt="" class="logo"/>
        </div>
      </div>
      <div className="right">
        <div className="wrapper">
          <h2>Hi There,  I'm</h2>
          <h1>Rick Mastry</h1>
          <h3>Freelance <span ref={textRef}></span></h3>
          <a href="#portfolio">
            <img src="assets/down.png" alt="" />
          </a>
        </div>
       
      </div>
    </div>
  )
}
